<template>
  <v-container
    id="login"
    fill-height
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <v-form
          action="#"
          @submit.prevent="submit"
        >
          <base-material-card
            color="success"
            light
            max-width="100%"
            width="400"
            class="px-5 py-3"
          >
            <template v-slot:heading>
              <div class="text-center">
                <h1 class="display-2 font-weight-bold mb-2">
                  Stack Energy Admin
                </h1>
              </div>
            </template>

            <v-card-text class="text-center">
              <v-text-field
                v-model="form.email"
                color="secondary"
                label="Email..."
                prepend-icon="mdi-email"
              />

              <v-text-field
                v-model="form.password"
                type="password"
                class="mb-8"
                color="secondary"
                label="Password..."
                prepend-icon="mdi-lock-outline"
              />

              <pages-btn
                large
                color=""
                depressed
                class="v-btn--text success--text"
                @click="submit"
              >
                Let's Go
              </pages-btn>
            </v-card-text>
          </base-material-card>
        </v-form>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PagesLogin',

  components: {
    PagesBtn: () => import('./components/Btn')
  },

  data: () => ({
    form: {
      email: '',
      password: ''
    }
  }),

  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),

    async submit() {
      await this.signIn(this.form)

      this.$router.push('/')
    }
  }
}
</script>
